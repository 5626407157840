<template>
  <div class="Waiting_wrapper">
    <div class="Waiting_logo">
      <img alt="Waiting page logo" src="@/assets/img/_common/financeLogoBlue.svg" />
    </div>
    <div class="Waiting_title">
      Waiting payment
      <div class="Invoice_title-back" @click="backHandler">
        <img alt="Waiting page logo" src="@/assets/img/invoice/back-arrow.svg" />
        go back
      </div>
    </div>
    <div class="Waiting_border"></div>
    <div class="Waiting_inner" v-if="!PAYMENT_TYPES[invoice.pay_currency]">
      <div class="Waiting_transaciton">
        <div class="Waiting_qr">
          <qr-code color="#2D4D70" bg-color="transparent" :size="90" :text="invoice && invoice.url ? invoice.url : ''"></qr-code>
        </div>
        <div class="Waiting_desc">
          <div class="Waiting_countdown">
            <iconCountdown fillColor="#5abc67" />
            <div class="Waiting_countdown__inner">
              <span v-if="invoice.invoice_currency === invoice.pay_currency">unlimited</span>
              <vue-countdown v-else @progress="countDownHandler" :time="15 * 60 * 1000" v-slot="{ minutes, seconds }">
                {{ minutes }}:{{ seconds }}
              </vue-countdown>
            </div>
          </div>
          <p>When time runs out - this page will be refreshed with a new exchange rate</p>
          <div v-if="order_description">
            <h2>Description:</h2>
            <p>{{ order_description }}</p>
          </div>
        </div>
      </div>
      <div class="Waiting_border Waiting_border__sml"></div>
      <div class="Waiting_item">
        <div class="Waiting_item-inner">
          <span>Invoice Amount</span>
          {{ invoice.invoice_amount }} {{ invoice.invoice_currency }}
        </div>
        <div class="Waiting_item-inner">
          <span>Amount to pay</span>
          {{ invoice.pay_amount }} {{ invoice.pay_currency }}
        </div>
      </div>
      <div class="Waiting_border Waiting_border__sml"></div>
      <Input
        name="address"
        label="address"
        :value="hadnleInvoiceAddress"
        showBtnCopy
        shouldCopyValue
        disabled
        disabledColor="#fff"
        showQrIcon
        @clickBtn="copyAddress"
      />
      <p class="Waiting_description">Please send funds to the specified address or use a QR code</p>
      <span class="Waiting_warning"><InfoPseudoIcon color="red" />Transfer of another currency is unrecoverable</span>
      <div class="Waiting_reload" @click="getInvoiceStatus">
        <div class="Waiting_reload-arrow"><circleArrow :fillColor="`#fff`" /></div>
        Waiting for payment
      </div>
    </div>
    <div class="Waiting_inner Waiting_inner-invoice" v-else>
      <div class="Waiting_inner-invoiceInner">
        <iframe :src="hadnleInvoiceAddress"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Input from '@/components/_common/FormElements/Input/Input';
import { iconCountdown } from '@/assets/icons/common';
import { copyToClipboard } from '@/utils/helpers';
import { InfoPseudoIcon } from '@/assets/icons/arrows';
import { circleArrow } from '@/assets/icons/arrows';
import PAYMENT_TYPES from './PaymentTypes';
export default {
  components: {
    iconCountdown,
    Input,
    InfoPseudoIcon,
    circleArrow,
  },
  data() {
    return {
      copyToClipboard,
      invoiceAddress: '',
      invoice: '',
      polling: null,
      order_description: '',
      PAYMENT_TYPES,
    };
  },
  computed: {
    hadnleInvoiceAddress() {
      const address = this.invoiceAddress;
      return address;
    },
  },
  methods: {
    ...mapActions('invoicing', ['apiGetInvoiceInfo']),
    backHandler() {
      this.$router.push({ name: 'invoice', params: { id: this.invoice.invoice_id } });
    },
    countDownHandler(time) {
      if (time.minutes === 0 && time.seconds === 0) {
        this.getInvoiceStatus();
      }
    },
    getInvoiceStatus() {
      if (this.invoice.invoice_id) {
        this.apiGetInvoiceInfo(this.invoice.invoice_id).then(res => {
          this.order_description = res.data.order_details.details.comment ? res.data.order_details.details.comment : '';
          if (res.data.order_details.status !== 'WAITING_FOR_CONFIRMATION') {
            this.$router.push({ name: 'invoice.statements.statement', params: res });
          }
        });
      }
    },
    copyAddress() {
      this.copyToClipboard(this.invoiceAddress);
    },
    pollInvoiceStatus() {
      this.polling = setInterval(() => {
        this.getInvoiceStatus();
      }, 15000);
    },
  },
  created() {
    this.pollInvoiceStatus();
  },
  mounted() {
    this.invoiceAddress = this.$route.params.data.url;
    this.invoice = this.$route.params.data;
    this.invoice.invoice_currency = this.$route.params.invoice_currency;
    this.invoice.invoice_amount = this.$route.params.invoice_amount;
    this.invoice.invoice_id = this.$route.params.invoice_id;
    this.getInvoiceStatus();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
};
</script>

<style lang="scss">
@import './Waiting.scss';
.Waiting_inner-invoice {
  &Inner {
    height: 477px;
  }
  iframe {
    border: none;
    background: #fff;
    width: 100%;
    min-height: 477px;
  }
}
.Invoice_title-back {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: #2af3f3;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    font-size: 12px;
  }
  &::before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
    background: #2af3f3;
    position: absolute;
    @media only screen and (min-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }
  img {
    margin: 0 6px 0 2px;
    z-index: 2;
    position: relative;
    @media only screen and (min-width: 768px) {
      margin: 0 11px 0 5px;
    }
  }
}
.Waiting_title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
</style>
